import React, { useEffect, useMemo, useState } from "react";
import {  uploadFileFolderService } from "../../../api";
import { Loading } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";
import { ISources } from "../../guideline-compliance";
import "../source-popup/source.scss";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

interface Props {
  chatSource: ISources;
  setCurrPage?: (page: number) => void;
  guideline?: string;
  isCompliantDoc?: boolean;
}

export const HighlightedSource = ({ chatSource, setCurrPage, guideline, isCompliantDoc = false }: Props) => {
  const { documentUUID, pages } = chatSource;
  const [currentPageData, setCurrentPageData] = useState<any>(pages[0]);
  const [currentSourceContent, setCurrentSourceContent] = useState("");
  const [isSourceContentLaoding, setIsSourceContentLoading] = useState(false);
  const { currentPage : globalCurrPage, currentChunkUUID } = useSelector((state: RootState) => state.document);
  const highlightedSourceContent = useMemo(() => {
    let content = currentSourceContent;
    const matchingPage: any = currentChunkUUID 
    ? chatSource.pages.find(page => page.chunkUUID === currentChunkUUID)
    : null;

  const terms: any = matchingPage?.offsets?.length
    ? matchingPage.offsets.map((offset: any) =>{
      return(
        (offset.begin===0 && offset.end===0) ? currentSourceContent :
        currentSourceContent?.substring(offset.begin, offset.end)
      )
      })
    : [];
    if(pages.map(({ pageNo }) => pageNo).includes(currentPageData.pageNo || globalCurrPage)) {
      terms.forEach((term: any) => {
        const html = `<span class="bg-yellow">${term}</span>`;
        content = content?.replace(term, html);
      });
      content = content?.replaceAll(`\n`, `<br/>`);
    }
    return content;
  }, [currentPageData.offsets, currentSourceContent,currentChunkUUID ]);

  const fetchSourceContent = async (page: number) => {
    setCurrentSourceContent("")
    setIsSourceContentLoading(true);
    try {
      const payload: any = {
        fileUuid: documentUUID,
        pageNum: page,
        guideline: guideline
      };

      if(isCompliantDoc) {
        payload["docVariant"] = "guidelines";
      }
      const { data } = await uploadFileFolderService.getFileOCR(payload);
      setCurrentSourceContent(data.data.ocr_text);
    } catch (err: any) {
        openNotificationWithIcon("", "Document not found", "error");
    } finally {
      setIsSourceContentLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPageData(pages.find(({ pageNo }) => pageNo === globalCurrPage) || { pageNo: globalCurrPage, offsets: [] });
    fetchSourceContent(globalCurrPage);
  },[ globalCurrPage, documentUUID])

  if(isSourceContentLaoding) {
    return <Loading className="p-20"/>
  }

  return (
    <div className="source-content">
      <span dangerouslySetInnerHTML={{ __html: highlightedSourceContent }} />
    </div>
  );
};
